// import { toTheme } from "@theme-ui/typography"
// import noriega from "typography-theme-noriega"
// import merge from "deepmerge"

// const typographTheme = toTheme(noriega)
// import prism from "@theme-ui/prism/presets/theme-ui"

const syntaxColors = {
  padding: 3,
  backgroundColor: "codeBackgroundColor",
  ".comment,.prolog,.doctype,.cdata": {
    color: "comment",
  },
  ".punctuation": {
    color: "punctuation",
  },
  ".namespace": {
    opacity: ".7",
  },
  ".property,.tag,.constant,.symbol,.deleted": {
    color: "property",
  },
  ".boolean,.number": {
    color: "boolean",
  },
  ".selector,.attr-name,.string,.char,.builtin,.inserted": {
    color: "selector",
  },
  ".operator,.entity,.url,.language-css .string,.style .string,.variable": {
    color: "operator",
  },
  ".atrule,.attr-value,.function,.class-name": {
    color: "atrule",
  },
  ".keyword": {
    color: "keyword",
  },
  ".regex,.important": {
    color: "regex",
  },
  ".important,.bold": {
    fontWeight: "bold",
  },
  ".italic": {
    fontStyle: "italic",
  },
  ".entity": {
    cursor: "help",
  },
  ".highlight": {
    background: "hsla(0, 0%, 30%, .5)",
  },
}

export default {
  breakpoints: ["478px", "767px", "991px", "1200px", "2000px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 900,
  },
  fonts: {
    body: `'Nunito', sans-serif`,
    heading: `'Lato', sans-serif`,
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
    tags: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#14141c",
    // text: "#3f3f3f",
    background: "#ffffff",
    primary: "#07c",
    secondary: "#30c",
    muted: "#525252",
    // code syntax colors
    codeBackgroundColor: "#f9fbc7",
    comment: "#93a1a1",
    punctuation: "#586e75",
    property: "#268bd2",
    boolean: "#268bd2",
    selector: "#2aa198",
    operator: "#657b83",
    atrule: "#859900",
    keyword: "#b58900",
    regex: "#cb4b16",
    dataVizColors: [
      "#7FC97F",
      "#BDADD3",
      "#FDC086",
      "#FFFF99",
      "#386CB0",
      "#EF0077",
      "#BF5B16",
      "#666666",
    ],
    modes: {
      dark: {
        text: "#dadcf7",
        background: "#14141c",
        primary: "#07c",
        secondary: "#30c",
        muted: "#d1d1d1",
        // code syntax colors
        codeBackgroundColor: "#1c1c1c",
        comment: "slategray",
        punctuation: "#f8f8f2",
        property: "#f92672",
        boolean: "#ae81ff",
        selector: "#a6e22e",
        operator: "#f8f8f2",
        atrule: "#e6db74",
        keyword: "#66d9ef",
        regex: "#fd971f",
        dataVizColors: [
          "#1B9E77",
          "#D95F02",
          "#7570B3",
          "#E7298A",
          "#66A61E",
          "#E6AB02",
          "#A6761D",
          "#666666",
        ],
      },
    },
  },
  text: {
    heading: {
      // fontFamily: "heading",
      fontFamily: `'Lato', sans-serif`,
      lineHeight: "heading",
      fontWeight: "heading",
    },
    displayTitle: {
      fontFamily: `'Lato', sans-serif`,
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [5, 5, 6, 6],
      pt: 5,
      pb: 2,
    },
    mutedSubtitle: {
      color: "muted",
    },
  },
  links: {
    nav: {
      px: 2,
      py: 3,
      fontFamily: `'Lato', sans-serif`,
      color: "muted",
    },
  },
  layout: {
    container: {
      px: 3,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      variant: "text.heading",
      fontSize: [4, 4, 5, 5],
    },
    h2: {
      variant: "text.heading",
      fontSize: [3, 3, 4, 4],
    },
    h3: {
      variant: "text.heading",
      fontSize: [2, 2, 3, 3],
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    ul: {
      pl: 4,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      ...syntaxColors,
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    a: {
      color: "primary",
      textDecoration: "none",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}
